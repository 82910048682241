import Rest from '@/services/Rest';

/**
 * @typedef {DashboardService}
 */
export default class DashboardService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/dashboard'
}
