import _ from 'lodash'
import Cookies from "js-cookie"

export default (denounce_name, debounce_days = 7) => {
  const debounceCookie = Cookies.get('dbd_'+denounce_name)
  if(debounceCookie) return false

  Cookies.set('dbd_'+denounce_name, true, { expires: debounce_days })
  return true
}
